// src/pages/newpage.js
import React from 'react';
import { Helmet } from 'react-helmet';

const ResumeBlockchain = () => {
  const redirectUrl = 'https://drive.google.com/file/d/11PPr2zMPdnBOmxSQiAVkiWeSLHK5kO3b/view';
  return (
    <div>
      <Helmet>
        <meta httpEquiv="refresh" content={`0;url=${redirectUrl}`} />
      </Helmet>
    </div>
  );
};

export default ResumeBlockchain;
